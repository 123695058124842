import * as React from "react";
import { List,
    Datagrid,
    TextField,
    DeleteButton,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    NumberInput, 
    required } from 'react-admin';

    const ancho ={
        width:'42.6%'
    }

    export const GrupoPreciosList = props => (
        <List title={"Precio Seguros"} {...props}>
            <Datagrid rowClick="edit" >
                <TextField source="id" label="Id" />
                <ReferenceField source="idPrecio" reference="PrecioSegurosSelect" label="Edad Seguro" link={false}>
                    <TextField source="descripcion" />
                </ReferenceField>
                <ReferenceField source="cantidad" reference="Cantidad" label="Cantidad" link={false}>
                    <TextField source="descripcion" />
                </ReferenceField>
                <TextField source="factor" label="Factor" />
                <TextField source="fechaActualizacion" label="Fecha Actualización" />
                <DeleteButton />
            </Datagrid>
        </List>
    );

    export const GrupoPreciosEdit = props => (
        <Edit title="Editar Precio Seguro" {...props}>
            <SimpleForm className="simpleform">
                <TextInput disabled source="id" label="Id" className="texinput" style={ancho}/>
                <ReferenceInput source="idPrecio" reference="PrecioSegurosSelect" label="Edad Seguro" className="texinput" style={ancho} validate={requiredValidate}>
                    <SelectInput optionText="descripcion" value="id" />
                </ReferenceInput>
                <ReferenceInput source="cantidad" reference="Cantidad" label="Cantidad" className="texinput" style={ancho} validate={requiredValidate}>
                    <SelectInput optionText="descripcion" value="id" />
                </ReferenceInput>
                <NumberInput source="factor" label="Factor" className="texinput" validate={requiredValidate}/>
            </SimpleForm>
        </Edit>
    );

    export const GrupoPreciosCreate = props => (
        <Create title="Editar Precio Seguro" {...props}>
            <SimpleForm className="simpleform">
                <TextInput disabled source="id" label="Id" className="texinput" style={ancho}/>
                <ReferenceInput source="idPrecio" reference="PrecioSegurosSelect" label="Edad Seguro" className="texinput" style={ancho} validate={requiredValidate}>
                    <SelectInput optionText="descripcion" value="id" />
                </ReferenceInput>
                <ReferenceInput source="cantidad" reference="Cantidad" label="Cantidad" className="texinput" style={ancho} validate={requiredValidate}>
                    <SelectInput optionText="descripcion" value="id" />
                </ReferenceInput>
                <NumberInput source="factor" label="Factor" className="texinput" validate={requiredValidate}/>
            </SimpleForm>
        </Create>
    );
    const requiredValidate = [required()];