import * as React from "react";
import { List,
    Datagrid,
    TextField,
    DeleteButton,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    required,
    } from 'react-admin';
    const ancho ={
        width:'42.6%'
    }

    const formato ={
        marginLeft:'17rem',
        marginTop:'-4.7rem'
    }

    export const CatalogosList = props => (            
        <List title={"Catalogos"} {...props}>
            <Datagrid rowClick="edit" >
                <TextField source="id" label="Id" />
                <ReferenceField source="idTipoCatalogo" reference="TipoCatalogos" label="Tipo Catálogo" link={false}>
                    <TextField source="nombreTipoCatalogo" />
                </ReferenceField>
                <TextField source="nombre" label="Nombre" />
                <TextField source="fechaActualizacion" label="Fecha Actualización" />
                <DeleteButton/>
            </Datagrid>
        </List>
    );

    export const CatalogosEdit = props => (
        <Edit {...props} label="Editar Seguro">
            <SimpleForm className=" simpleform">
            <TextInput disabled source="id" label="Id"/>
                <ReferenceInput source="idTipoCatalogo" reference="TipoCatalogos" label="Tipo"  style={formato} validate={requiredValidate}>
                    <SelectInput optionText="nombreTipoCatalogo" value="id" />
                </ReferenceInput>
                <TextInput source="nombre" label="Nombre" multiline={true} style={ancho} validate={requiredValidate}/>
                <TextInput source="descripcion" label="Descripción" multiline={true} style={ancho} validate={requiredValidate}/>
                <TextInput source="imagen" label="Imagen"/>
                <TextInput source="usuario" label="Usuario" style={formato}/>
            </SimpleForm>
        </Edit>
    );



    export const CatalogosCreate = props => (
        <Create {...props} label="Crear Seguro">
            <SimpleForm className="simpleform">
            <TextInput disabled source="id" label="Id"/>
                <ReferenceInput source="idTipoCatalogo" reference="TipoCatalogos" label="Tipo" style={formato} validate={requiredValidate}>
                    <SelectInput optionText="nombreTipoCatalogo" value="id" />
                </ReferenceInput>
                <TextInput source="nombre" label="Nombre" className="nombreee" multiline={true} style={ancho} validate={requiredValidate}/>
                <TextInput source="descripcion" label="Descripción" multiline={true} style={ancho} validate={requiredValidate}/>
                <TextInput source="imagen" label="Imagen"  validate={requiredValidate}/>
                <TextInput source="usuario" label="Usuario" style={formato} validate={requiredValidate}/>
            </SimpleForm> 
        </Create>
    );

    const requiredValidate = [required()];