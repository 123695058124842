// in src/App.js
import * as React from "react";
import { fetchUtils, Admin, Resource } from 'react-admin';
import { TipoSegurosList, TipoSegurosEdit, TipoSegurosCreate } from './components/TipoSeguros';
import { SegurosList, SegurosEdit, SegurosCreate } from './components/Seguros';
import { CatalogosList, CatalogosEdit, CatalogosCreate } from './components/Catalogos'
import { PrecioSegurosList, PrecioSegurosEdit, PrecioSegurosCreate } from './components/PrecioSeguros'
import { UsuariosList, UsuariosEdit, UsuariosCreate } from './components/Usuarios'
import { GrupoPreciosList, GrupoPreciosEdit, GrupoPreciosCreate } from './components/GrupoPrecios'
import { AuditoriasList } from './components/Auditoria'
import jsonServerProvider from 'ra-data-json-server';
import { Layout } from './layout';
import authProvider from './login/authProvider';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}
const dataProvider = jsonServerProvider(`${process.env.REACT_APP_URI_SERVER}/api/Catalogos`, httpClient);
const App = () => {
    return (
        <Admin
            title="Mantenimiento Portal Metlife"
            dataProvider={dataProvider}
            //customReducers={{ theme: themeReducer }}
            //customRoutes={customRoutes}
            authProvider={authProvider}
            //dashboard={Dashboard}
            //loginPage={Login}
            layout={Layout}
            //i18nProvider={i18nProvider}
            disableTelemetry>   
            <Resource name="Auditorias" list={AuditoriasList} />
            <Resource name="Usuarios" list={UsuariosList} edit={UsuariosEdit} create={UsuariosCreate} />        
            <Resource name="TipoSeguros" list={TipoSegurosList} edit={TipoSegurosEdit} create={TipoSegurosCreate} />
            <Resource name="Seguros" list={SegurosList} edit={SegurosEdit} create={SegurosCreate} />
            <Resource name="TipoCatalogos" />
            <Resource name="PrecioSegurosSelect" />
            <Resource name="Cantidad" />
            <Resource name="Catalogos" list={CatalogosList} edit={CatalogosEdit} create={CatalogosCreate} />
            <Resource name="PrecioSeguros" list={PrecioSegurosList} edit={PrecioSegurosEdit} create={PrecioSegurosCreate} />
            <Resource name="GrupoPrecios" list={GrupoPreciosList} edit={GrupoPreciosEdit} create={GrupoPreciosCreate} />
        </Admin>
    );
};

export default App;