import * as React from "react";
import { List,
    Datagrid,
    TextField,
    DeleteButton,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    NumberInput, 
    required } from 'react-admin';

    const ancho ={
        width:'42.6%'
    }

    const formato ={
        marginLeft:'17rem',
        marginTop:'-4.7rem'
    }

    export const PrecioSegurosList = props => (
        <List title={"Edad Seguros"} {...props}>
            <Datagrid rowClick="edit" >
                <TextField source="id" label="Id" />
                <ReferenceField source="idSeguro" reference="Seguros" label="Seguro" link={false}>
                    <TextField source="nombreSeguro" />
                </ReferenceField>
                <TextField source="edadInferior" label="Edad Inferior" />
                <TextField source="edadSuperior" label="Edad Superior" />
                <TextField source="fechaActualizacion" label="Fecha Actualización" />
                <DeleteButton />
            </Datagrid>
        </List>
    );

    export const PrecioSegurosEdit = props => (
        <Edit title="Editar Edad Seguro" {...props}>
            <SimpleForm className="simpleform">
                <TextInput disabled source="id" label="Id" className="texinput" style={ancho}/>
                <ReferenceInput source="idSeguro" reference="Seguros" label="Seguro" className="texinput" style={ancho} validate={requiredValidate}>
                    <SelectInput optionText="nombreSeguro" value="id" />
                </ReferenceInput>
                <NumberInput source="edadInferior" label="Edad Inferior" className="texinput" validate={requiredValidate}/>
                <NumberInput source="edadSuperior" label="Edad Superior" className="texinput" style={formato} validate={requiredValidate}/>
            </SimpleForm>
        </Edit>
    );

    export const PrecioSegurosCreate = props => (
        <Create title="Crear Edad Seguro" {...props}>
            <SimpleForm className="simpleform">
                <TextInput disabled source="id" label="Id" className="texinput" style={ancho}/>
                <ReferenceInput source="idSeguro" reference="Seguros" label="Seguro" className="texinput" style={ancho} validate={requiredValidate}>
                    <SelectInput optionText="nombreSeguro" value="id" className="texinput" style={ancho}/>
                </ReferenceInput>
                <NumberInput source="edadInferior" label="Edad Inferior" className="texinput" validate={requiredValidate}/>
                <NumberInput source="edadSuperior" label="Edad Superior" className="texinput" style={formato}  validate={requiredValidate}/>
            </SimpleForm>
        </Create>
    );

    const requiredValidate = [required()];