import * as React from "react";
import { List,
    Datagrid,
    TextField,
    DeleteButton,
    BooleanField, 
    Create,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    required  } from 'react-admin';

    const ancho ={
        width:'42.6%'
    }

    const formato ={
        marginLeft:'17rem',
        marginTop:'-4.7rem'
    }

    export const SegurosList = props => (
        <List  title={"Seguros"} {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" label="Id" />
                <ReferenceField source="idTipoSeguro" reference="TipoSeguros" label="Tipo Seguro" link={false}>
                    <TextField source="nombreTipoSeguro" />
                </ReferenceField>
                <TextField source="nombreSeguro" label="Nombre" />
                <BooleanField source="esDestacado" label="Destacado" />
                <TextField source="fechaActualizacion" label="Fecha Actualización" />
                <DeleteButton />
            </Datagrid>
        </List>
    );

    export const SegurosEdit = props => (
        <Edit {...props} label="Editar Seguro">
            <SimpleForm className="simpleform">
                <TextInput disabled source="id" label="Id" className="texinput"/>
                <ReferenceInput source="idTipoSeguro" reference="TipoSeguros" label="Tipo Seguro" className="texinput" style={formato} validate={requiredValidate}>
                    <SelectInput optionText="nombreTipoSeguro" value="id" />
                </ReferenceInput>
                <TextInput source="nombreSeguro" label="Nombre" className="texinput" style={ancho} validate={requiredValidate}/>
                <BooleanInput source="esDestacado" label="Destacado" className="esdestacado"/>
                <TextInput source="capitalAseguradoAclaracion" label="Capital asegurado aclaracion" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="capitalAsegurado" label="Capital asegurado" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="capitalAseguradoComentario" label="Capital asegurado comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="capitalAseguradoToolTip" label="Capital asegurado tooltip" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="deducibleAclaracion" label="deducible aclaración" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="deducible" label="Deducible" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="deducibleToolTip" label="Deducible toolTip" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="cobertura" label="Cobertura" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput  source="condiciones" label="Condiciones" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="maternidad" label="Maternidad" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="maternidadComentario" label="Maternidad comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="maternidadAsterisco" label="Maternidad asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="psiquiatria" label="Psiquiatria" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="psiquiatriaComentario" label="Psiquiatria comentario" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="psiquiatriaAsterisco" label="Psiquiatria asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="dermatologo" label="Dermatologo" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="dermatologoComentario" label="Dermatologo comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="dermatologoAsterisco" label="Dermatologo asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="urgencias" label="Urgencias" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="urgenciasComentario" label="Urgencias comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="urgenciasAsterisco" label="Urgencias asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="cirugiaRodilla" label="Cirugia rodilla" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="cirugiaRodillaComentario" label="cirugia rodilla comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="cirugiaRodillaAsterisco" label="Cirugia rodilla asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="uci" label="Uci" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="uciComentario" label="Uci comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="uciAsterisco" label="Uci asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="clinica1" label="Clinica 1" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="clinica2" label="Clinica 2" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="clinica3" label="Clinica 3" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="url" label="Url"  multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="edadMaxima" label="Edad maxima" className="texinput" style={ancho} validate={requiredValidate}/>
                {/* <TextInput source="url" label="Url" className="texinput" validate={requiredValidate}/>
                <TextInput source="capitalAsegurado" label="Capital Asegurado" className="texinput" validate={requiredValidate}/>
                <TextInput source="deducible" label="Deducible" className="texinput" style={formato} validate={requiredValidate}/>
                <TextInput source="cobertura" label="Cobertura" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="condiciones" label="Condiciones" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/> */}
            </SimpleForm>
        </Edit>
    );

    export const SegurosCreate = props => (
        <Create {...props} label="Crear Seguro">
            <SimpleForm className="simpleform" >
                <TextInput disabled source="id" label="Id" className="texinput"/>
                <ReferenceInput source="idTipoSeguro" reference="TipoSeguros" label="Tipo Seguro" className="texinput" style={formato} validate={requiredValidate}>
                    <SelectInput optionText="nombreTipoSeguro" value="id" />
                </ReferenceInput>
                <TextInput source="nombreSeguro" label="Nombre" className="texinput" style={ancho} validate={requiredValidate}/>
                <BooleanInput source="esDestacado" label="Destacado" className="esdestacado"/>
                <TextInput source="capitalAseguradoAclaracion" label="Capital asegurado aclaracion" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="capitalAsegurado" label="Capital asegurado" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="capitalAseguradoComentario" label="Capital asegurado comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="capitalAseguradoToolTip" label="Capital asegurado tooltip" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="deducibleAclaracion" label="deducible aclaración" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="deducible" label="Deducible" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="deducibleToolTip" label="Deducible toolTip" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="cobertura" label="Cobertura" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput  source="condiciones" label="Condiciones" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="maternidad" label="Maternidad" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="maternidadComentario" label="Maternidad comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="maternidadAsterisco" label="Maternidad asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="psiquiatria" label="Psiquiatria" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="psiquiatriaComentario" label="Psiquiatria comentario" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="psiquiatriaAsterisco" label="Psiquiatria asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="dermatologo" label="Dermatologo" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="dermatologoComentario" label="Dermatologo comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="dermatologoAsterisco" label="Dermatologo asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="urgencias" label="Urgencias" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="urgenciasComentario" label="Urgencias comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="urgenciasAsterisco" label="Urgencias asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="cirugiaRodilla" label="Cirugia rodilla" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="cirugiaRodillaComentario" label="cirugia rodilla comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="cirugiaRodillaAsterisco" label="Cirugia rodilla asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="uci" label="Uci" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="uciComentario" label="Uci comentario" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="uciAsterisco" label="Uci asterisco" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="clinica1" label="Clinica 1" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="clinica2" label="Clinica 2" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="clinica3" label="Clinica 3" className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="url" label="Url"  multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="edadMaxima" label="Edad maxima" className="texinput" style={ancho} validate={requiredValidate}/>
                {/* <TextInput source="url" label="Url" className="texinput" validate={requiredValidate}/>
                <TextInput source="capitalAsegurado" label="Capital Asegurado" className="texinput" validate={requiredValidate}/>
                <TextInput source="deducible" label="Deducible" className="texinput" style={formato} validate={requiredValidate}/>
                <TextInput source="cobertura" label="Cobertura" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/>
                <TextInput source="condiciones" label="Condiciones" multiline={true} className="texinput" style={ancho} validate={requiredValidate}/> */}
            </SimpleForm>
        </Create>
    );

    const requiredValidate = [required()];