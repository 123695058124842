import * as React from "react";
import { List,
    Datagrid,
    TextField,
    DeleteButton,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    required, } from 'react-admin';

    const ancho ={
        width:'42.6%'
    }

export const TipoSegurosList = props => (
    <List title={"Tipo de Seguros"} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Id" />
            <TextField source="nombreTipoSeguro" label="Tipo Seguro" />
            <TextField source="fechaActualizacion" label="Fecha Actualización"/>
            <DeleteButton />
        </Datagrid>
    </List>
);

export const TipoSegurosEdit = props => (
    <Edit title={"Edit Tipo de Seguro"} {...props}>
        <SimpleForm className="simpleform">
            <TextInput disabled source="id" label="Id" className=" texinput" style={ancho}/>
            <TextInput source="nombreTipoSeguro" label="Tipo Seguro" className=" texinput" style={ancho} validate={requiredValidate}/>
        </SimpleForm>
    </Edit>
);

export const TipoSegurosCreate = props => (
    <Create title={"Create Tipo de Seguro"} {...props}>
        <SimpleForm className="simpleform">
            <TextInput disabled source="id" label="Id" className=" texinput" style={ancho}/>
            <TextInput source="nombreTipoSeguro" label="Tipo Seguro" className=" texinput" style={ancho} validate={requiredValidate}/>
        </SimpleForm>
    </Create>
);

const requiredValidate = [required()];