import { MenuItemLink } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
import LabelIcon from '@material-ui/icons/Label';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

const Menu = () => (
    <div><br/>
        <MenuItemLink to= "/Usuarios" primaryText="Usuarios" leftIcon={<SupervisedUserCircleIcon className="icon"/>}/>
        <MenuItemLink to= "/Auditorias" primaryText="Auditoría" leftIcon={<HistoryIcon className="icon"/>}/>
        <MenuItemLink to= "/Catalogos" primaryText="Catálogos" leftIcon={<BookIcon className="icon"/>}/>
        <MenuItemLink to="/TipoSeguros" primaryText="Tipo de Seguros" leftIcon={<LabelIcon className="icon"/>}/>
        <MenuItemLink to="/Seguros" primaryText="Seguros" leftIcon={<LocalHospitalIcon className="icon"/>}/>
        <MenuItemLink to="/PrecioSeguros" primaryText="Edad Seguros" leftIcon={<PeopleIcon className="icon"/>}/>
        <MenuItemLink to="/GrupoPrecios" primaryText="Precio Seguros" leftIcon={<AttachMoneyIcon className="icon"/>}/>
    </div>
);
export default Menu;