import * as React from "react";
import { List,
    Datagrid,
    TextField,
    DeleteButton,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    PasswordInput,
    required, } from 'react-admin';

    const ancho ={
        width:'42.6%'
    }

export const UsuariosList = props => (
    <List title={"Usuarios"} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Id" />
            <TextField source="username" label="Usuario" />
            <TextField source="emailAddress" label="Correo" />
            <TextField source="lastLogin" label="Último Ingreso"/>
            <TextField source="fechaActualizacion" label="Fecha Actualización"/>
            <DeleteButton />
        </Datagrid>
    </List>
);

export const UsuariosEdit = props => (
    <Edit title={"Edit Tipo de Seguro"} {...props}>
        <SimpleForm className="simpleform">
            <TextInput disabled source="id" label="Id" className="texinput" style={ancho}/>
            <TextInput source="username" label="Usuario" className="texinput" style={ancho} validate={requiredValidate}/>
            <TextInput source="emailAddress" label="Correo" className="texinput" style={ancho} validate={requiredValidate}/>
            <PasswordInput source="password" label="Contraseña" className="texinput" style={ancho} validate={requiredValidate} />
        </SimpleForm>
    </Edit>
);

export const UsuariosCreate = props => (
    <Create title={"Create Tipo de Seguro"} {...props}>
        <SimpleForm className="simpleform">
            <TextInput disabled source="id" label="Id" className="texinput" style={ancho}/>
            <TextInput source="Username" label="Usuario" className="texinput" style={ancho} validate={requiredValidate}/>
            <TextInput source="EmailAddress" label="Correo" className="texinput" style={ancho} validate={requiredValidate}/>
            <PasswordInput source="password" label="Contraseña" className="texinput" style={ancho} validate={requiredValidate} />
        </SimpleForm>
    </Create>
);

const requiredValidate = [required()];