import * as React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { lightTheme } from './themes';
//import { AppState } from '../types';

const menu = (props: LayoutProps) => {
    return <Layout {...props} 
    appBar={AppBar} 
    menu={Menu} 
    theme={lightTheme} 
    />;
};
export default menu;
