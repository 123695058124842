import * as React from "react";
import { List,
    Datagrid,
    TextField    
    } from 'react-admin';

    export const AuditoriasList = props => (                
        <List title={"Auditoría"} {...props}>
            <Datagrid rowClick="edit" >
                <TextField source="id" label="Id" />                
                <TextField source="controlador" label="Controlador" />
                <TextField source="accion" label="Accion" />
                <TextField source="userName" label="Usuario" />
                <TextField source="fecha" label="Fecha" />
                <TextField source="registro" label="Registro" />
            </Datagrid>
        </List>
    );
